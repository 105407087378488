<template>
<div class="services container-fluid px-0 mb-5">
    <router-view></router-view>
</div>
</template>

<script>
    export default {
        name: "Services"
    }
</script>